<template>
  <div class="alarmSetting">
    <el-tabs
      type="border-card"
      @tab-click="clickTab"
      v-model="requestObj.alarmSpecies"
      tab-position="top"
      class="alarm-tab"
    >
      <el-tab-pane
        v-for="(item, index) in tabOptions"
        :key="index"
        :label="item.dictValue"
        :name="item.dictCode"
      >
        <!-- 查询 -->
        <div class="date btnBox table-search">
          <my-search
            :dictList="dictList"
            :alarmOptions="[alarmOptions[index]]"
            @sendInfo="getSearchInfo"
            @batchSet="batchSet"
          ></my-search>
        </div>
        <!-- 表格 -->
        <my-table
          :table_data="tableData"
          :dictObj="dictObj"
          @editTable="editTable"
          @changeSelect="changeSelect"
          @refreshTable="getAlarmSettingListFn"
        ></my-table>
        <!-- 分页 -->
        <my-page
          ref="pagination"
          @sendInfo="currentPageChange"
          :pageTotal="total"
          class="pagination"
        ></my-page>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :close-on-click-modal="false"
      width="40%"
      title="报警设置"
      :visible.sync="dialogTableVisible"
      v-if="dialogTableVisible"
      top="10vh"
    >
      <alarmEdit
        @submitSuccess="submitSuccess"
        :editData="editData"
        :dictList="dictList"
        :dictObj="dictObj"
        :batchList="batchList"
      />
    </el-dialog>
  </div>
</template>

<script>
import alarmEdit from './alarmEdit/alarmEdit.vue'
import search from './search/search.vue'
import table from './table/table.vue'
import pagination from '@/components/pagination/pagination.vue'
import { formatDict } from '@/common/utils/index'
import {
  getAlarmConfigs,
  queryDictsByCodes,
  getAlarmNameList
} from '@/api/lib/api.js'
export default {
  components: {
    'my-search': search,
    'my-table': table,
    'my-page': pagination,
    alarmEdit
  },
  data() {
    return {
      tableData: [],
      dialogTableVisible: false,
      editData: {},
      batchList: [],
      alarmOptions: [],
      total: 0,
      dictList: {},
      dictObj: {},
      requestObj: {
        currentPage: 1,
        pageSize: 10,
        system: '0',
        alarmSource: '',
        alarmSpecies: 'D0008'
      },
      tabOptions: []
    }
  },

  methods: {
    // 表格多选
    changeSelect(row) {
      this.batchList = []
      row.forEach((item) => {
        this.batchList.push(item.id)
      })
    },

    batchSet() {
      if (this.batchList.length === 0) {
        this.$message.warning('请选择至少一条报警')
      } else {
        this.editData = {}
        this.dialogTableVisible = true
      }
    },
    editTable(row) {
      this.editData = row
      if (row.alarmSpecies === 'D0008') {
        this.editData.alarmName = this.dictObj.D0008Obj[row.alarmType]
      } else if (row.alarmSpecies === 'D0009') {
        this.editData.alarmName = this.dictObj.D0009Obj[row.alarmType]
      } else if (row.alarmSpecies === 'D0010') {
        this.editData.alarmName = this.dictObj.D0010Obj[row.alarmType]
      }
      this.dialogTableVisible = true
    },

    submitSuccess(val) {
      if (val === 1) {
        this.getAlarmSettingListFn()
      }
      this.dialogTableVisible = false
    },

    clickTab(tab, event) {
      // this.requestObj = {
      //   // alarmSource: '',
      //   currentPage: 1,
      //   pageSize: 10,
      //   alarmSpecies: tab.name
      // }
      this.requestObj.alarmSpecies = tab.name
      this.requestObj.currentPage = 1
      this.getAlarmSettingListFn()
    },

    // 获取请求参数
    getSearchInfo(params) {
      if (this.$refs.pagination) {
        Object.assign(this.requestObj, params)
        // this.$refs.pagination[Number(this.requestObj.alarmSpecies) - 1].reset()
        this.requestObj.currentPage = 1
        this.requestObj.pageSize = 10
        // this.requestObj.alarmSource = "终端";
        this.getAlarmSettingListFn()
      }
    },

    // 获取报警配置列表数据
    getAlarmSettingListFn() {
      // 多维数组转成一维数组，并去掉一级报警类型
      if (this.requestObj.alarmTypeList.length >= 1) {
        var newAlarmType = []
        var temAlarmType = [
          ...new Set([...this.requestObj.alarmTypeList.flat(Infinity)])
        ]
        temAlarmType.forEach((item) => {
          if (item !== 'D0008' && item !== 'D0009' && item !== 'D0010') {
            newAlarmType.push(item)
          }
        })
        this.requestObj.alarmTypeList = newAlarmType
      }
      getAlarmConfigs(this.requestObj).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },

    // 分页参数改变
    currentPageChange(val) {
      Object.assign(this.requestObj, val)
      this.getAlarmSettingListFn()
    },

    // 获取报警类型
    getAlarmTypeFn() {
      const self = this
      self.alarmOptions = []
      self.dictList.alarmList.forEach((item) => {
        self.alarmOptions.push({
          label: item.dictValue,
          value: item.dictCode,
          children: []
        })
      })
      getAlarmNameList({ alarmSpecies: 'D0008,D0009', system: '0' }).then(
        (res) => {
          if (res.code === 1000) {
            res.data.forEach((item, index) => {
              if (item.alarmSpecies === 'D0008') {
                self.alarmOptions[0].children.push({
                  label: self.dictObj.D0008Obj[item.alarmType],
                  value: item.alarmType
                })
              } else if (item.alarmSpecies === 'D0009') {
                self.alarmOptions[1].children.push({
                  label: self.dictObj.D0009Obj[item.alarmType],
                  value: item.alarmType
                })
              } else {
                // self.alarmOptions[2].children.push({ label: self.dictObj.D0010Obj[item.alarmType], value: item.alarmType })
              }
            })
          }
        }
      )
    },

    //获取字典值
    getDicts() {
      queryDictsByCodes({
        parentCodes:
          'BJDJ,CLLX,BJLY,BJTC,BJSY,SYLX,KFXS,CLFS,BJZL,D0008,D0009,D0010'
      }).then((res) => {
        if (res.code === 1000) {
          res.data.BJZL.splice(2, 1) //删除终端故障报警配置
          this.dictList = {
            alarmLevelList: res.data.BJDJ,
            disposeTypeList: res.data.CLLX,
            alarmSourceList: res.data.BJLY,
            popupList: res.data.BJTC,
            voiceList: res.data.BJSY,
            musicTypeList: res.data.SYLX,
            deductList: res.data.KFXS,
            disposeWayList: res.data.CLFS,
            alarmList: res.data.BJZL
          }
          this.dictObj = {
            alarmLevelObj: formatDict(res.data.BJDJ),
            disposeTypeObj: formatDict(res.data.CLLX),
            alarmSourceObj: formatDict(res.data.BJLY),
            popupObj: formatDict(res.data.BJTC),
            voiceObj: formatDict(res.data.BJSY),
            deductObj: formatDict(res.data.KFXS),
            D0008Obj: formatDict(res.data.D0008),
            D0009Obj: formatDict(res.data.D0009),
            D0010Obj: formatDict(res.data.D0010)
          }
          this.tabOptions = res.data.BJZL

          this.getAlarmTypeFn()
        }
      })
    }
  },

  created() {
    this.getDicts()
  }
}
</script>

<style lang='scss' scoped>
.alarmSetting {
  height: 100%;
  // background: white;
  // padding: 1.5vh;
  & > .alarm-tab {
    height: 100%;
  }
  div.date {
    margin-bottom: -5px;
  }
  .el-table td {
    padding: 4px 0;
  }
}
</style>
