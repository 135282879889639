<template>
  <div class="alarmEdit">
    <div class="search-area">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        label-position="left"
        class="form-area alarm-edit"
      >
        <!-- 报警弹窗 -->
        <el-form-item label="报警弹窗：">
          <el-switch
            v-model="form.popup"
            :active-value="'1'"
            :inactive-value="'0'"
            inactive-color="#c7c8c9"
          ></el-switch>
        </el-form-item>

        <!-- 报警声音 -->
        <el-form-item label="报警声音：">
          <el-switch
            v-model="form.voice"
            :active-value="'1'"
            :inactive-value="'0'"
            inactive-color="#c7c8c9"
          ></el-switch>
        </el-form-item>

        <!-- 选择声音 -->
        <el-form-item label="选择声音类型：">
          <el-select @change="changeVoice" v-model="form.musicType" placeholder>
            <el-option
              v-for="item in dictList.musicTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 报警等级 -->
        <el-form-item label="报警等级">
          <el-select
            clearable
            v-model="form.alarmLevel"
            placeholder="请选择报警等级"
          >
            <el-option
              v-for="item in dictList.alarmLevelList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 报警名称 -->
        <el-form-item label="报警名称：" v-if="editData.id">
          <el-input disabled v-model.trim="form.alarmName"></el-input>
        </el-form-item>
        <!-- 扣分系数 -->
        <el-form-item label="扣分系数：">
          <el-select v-model="form.deduct" placeholder="请选择扣分系数">
            <el-option
              v-for="item in dictList.deductList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="未及时处理阈值：" prop="disposeTimeLimit">
          <el-input
            v-model.number.trim="form.disposeTimeLimit"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <!-- 处理方式 -->
        <el-form-item label="处理类型：">
          <el-col :md="11" :lg="11" :xl="8" class="dealtype">
            <el-form-item>
              <el-select
                v-model="form.disposeType"
                placeholder="请选择处理类型"
                @change="changeType"
              >
                <el-option
                  v-for="item in dictList.disposeTypeList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :md="11" :lg="11" :xl="8">
            <el-form-item
              class="dealtype"
              v-show="this.form.disposeType === '1'"
              :prop="form.disposeType === '1' ? 'disposeWay' : ''"
            >
              <el-select v-model="form.disposeWay" placeholder="请选择处理方式">
                <el-option
                  v-for="item in dictList.disposeWayList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>

        <!-- 警报播报内容 -->
        <el-form-item label="处理描述：">
          <el-input type="textarea" v-model="form.alarmDesc"> </el-input>
        </el-form-item>
      </el-form>
      <audio
        v-for="(item, index) in audioType"
        :id="item.id"
        :key="index"
        :src="item.src"
        muted
      ></audio>
    </div>
    <div class="buttonGroup text-right">
      <el-button size="small" type="default" @click="cancel">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="save"
        :loading="editLoading"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { updateAlarmConfigs } from '@/api/lib/api.js'

export default {
  props: {
    editData: {
      type: Object
    },
    batchList: {
      type: Array
    },
    dictList: {
      type: Object
    },
    dictObj: {
      type: Object
    }
  },
  data() {
    let checkRangeNumber = (rule, value, callback) => {
      if (value === '') {
        callback()
      } else {
        if (typeof value !== 'number' || value < 0 || value > 1440) {
          callback(new Error('请输入0-1440的整数'))
        } else {
          callback()
        }
      }
    }
    return {
      row: null,
      form: {
        disposeType: '1',
        disposeWay: null
      },
      audioType: [
        { id: 'audio1', src: '/static/static1/audio/alarmVoice(1).mp3' },
        { id: 'audio2', src: '/static/static1/audio/alarmVoice(2).mp3' },
        { id: 'audio3', src: '/static/static1/audio/alarmVoice(3).mp3' },
        { id: 'audio4', src: '/static/static1/audio/alarmVoice(4).mp3' },
        { id: 'audio5', src: '/static/static1/audio/alarmVoice(5).mp3' }
      ],
      rules: {
        disposeTimeLimit: [{ validator: checkRangeNumber, trigger: 'change' }],
        disposeWay: [
          { required: true, message: '请选择处理方式', trigger: 'blur' }
        ],
        alarmDesc: [
          { required: true, message: '请填写处理描述', trigger: 'change' }
        ]
      },
      editLoading: false
    }
  },
  created() {},

  mounted() {
    if (this.editData.id) {
      this.form = { ...this.editData }
      this.form.disposeWay =
        this.form.disposeType === '1' ? this.form.disposeWay : null
    } else {
      this.form.ids = this.batchList
    }
  },

  methods: {
    /* 改变声音试听 */
    changeVoice(v) {
      var audio = document.getElementById(`audio${v}`)
      audio.muted = false
      audio.play()
    },
    changeType() {
      this.form.disposeWay = null
    },

    /* 保存 */
    save() {
      let self = this
      self.$refs.form.validate((valid) => {
        if (valid) {
          this.editLoading = true
          let data = {
            ids: this.form.ids || [this.form.id],
            popup: this.form.popup,
            disposeType: this.form.disposeType,
            voice: this.form.voice,
            deduct: Number(this.form.deduct),
            alarmDesc: this.form.alarmDesc
              ? `调度员提醒您：${this.form.alarmDesc}`
              : '',
            disposeTimeLimit: this.form.disposeTimeLimit,
            musicType: this.form.musicType,
            alarmLevel: this.form.alarmLevel,
            disposeWay: this.form.disposeWay
          }
          updateAlarmConfigs(data).then((res) => {
            if (res.code === 1000) {
              this.$message.success('保存成功')
              this.$emit('submitSuccess', 1)
            } else {
              this.$message.error(res.msg)
            }
            this.editLoading = false
          })
        }
      })
    },
    /* 取消 */
    cancel() {
      this.$emit('submitSuccess', 0)
    }
  }
}
</script>

<style lang="scss" scoped>
// /deep/.alarm-edit {
//   .el-input__inner {
//     width: 184px;
//   }
//   .el-form-item {
//     margin-bottom: 16px;
//     &:last-of-type {
//       padding-bottom: 22px;
//     }
//   }
// }

/deep/.alarm-edit {
  .el-input__inner {
    width: 184px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .dealtype.el-form-item {
    margin-bottom: 0;
  }
}
.form-area {
  padding-bottom: 6px;
}
</style>
