<template>
  <div class="miniTable">
    <el-table
      @selection-change="changeSelect"
      stripe
      :data="table_data"
      height="calc(100vh - 315px)"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="popup" label="报警弹窗">
        <template slot-scope="scope">
          <el-switch
            @change="changePopup(scope.row)"
            v-model="scope.row.popup"
            :active-value="'1'"
            :inactive-value="'0'"
            :disabled="$store.state.menu.nowMenuList.indexOf('编辑') === -1"
            inactive-color="#c7c8c9"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="voice" label="报警声音">
        <template slot-scope="scope">
          <el-switch
            @change="changeVoice(scope.row)"
            v-model="scope.row.voice"
            :active-value="'1'"
            :inactive-value="'0'"
            :disabled="$store.state.menu.nowMenuList.indexOf('编辑') === -1"
            inactive-color="#c7c8c9"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="companyName" label="车队" :show-overflow-tooltip="true" width="200px"></el-table-column>
      <el-table-column prop="alarmType" label="报警名称" :show-overflow-tooltip="true" width="200px">
        <template slot-scope="scope">
          <span v-if="scope.row.alarmSpecies === 'D0008'">{{ dictObj.D0008Obj[Number(scope.row.alarmType)] }}</span>
          <span v-if="scope.row.alarmSpecies === 'D0009'">{{ dictObj.D0009Obj[Number(scope.row.alarmType)] }}</span>
          <span v-if="scope.row.alarmSpecies === 'D0010'">{{ dictObj.D0010Obj[Number(scope.row.alarmType)] }}</span>
          </template>
      </el-table-column>
      <el-table-column prop="deduct" label="扣分系数">
        <template slot-scope="scope">{{ dictObj.deductObj[Number(scope.row.deduct)] }}</template>
      </el-table-column>
      <el-table-column prop="alarmSource" label="报警来源">
        <template slot-scope="scope">{{ dictObj.alarmSourceObj[Number(scope.row.alarmSource)] }}</template>
      </el-table-column>
      <el-table-column prop="disposeType" label="处理类型">
        <template slot-scope="scope">{{ dictObj.disposeTypeObj[Number(scope.row.disposeType)] }}</template>
      </el-table-column>
      <el-table-column prop="disposeTimeLimit" width="130" label="超时处理时间(分)"></el-table-column>
      <el-table-column
        prop="updateTime"
        label="最近更新时间"
        width="180"
        :show-overflow-tooltip="true"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column prop="operator" label="更新人"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="edit(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { formatDate } from '@/common/utils/index'
import { updateAlarmConfigs } from '@/api/lib/api.js'

export default {
  props: {
    table_data: {
      type: Array
    },
    dictObj: {
      type: Object
    },
    alarmAllObj:{
      type: Object
    }
  },
  data () {
    return {}
  },

  methods: {
    edit (row) {
      this.$emit('editTable', row)
    },

    formatTime (row, column) {
      return formatDate(row.updateTime)
    },

    changeVoice (row) {
      this.editSetting(row)
    },

    changePopup (row) {
      this.editSetting(row)
    },

    editSetting (row) {
      let data = {
        ids: [row.id],
        popup: row.popup,
        voice: row.voice
      }
      updateAlarmConfigs(data).then(res => {
        if (res.code === 1000) {
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.msg)
        }
        this.$emit('refreshTable')
      })
    },

    // 切换选中项
    changeSelect (val) {
      this.$emit('changeSelect', val)
    }
  }
}
</script>

<style lang='scss' scoped>
.miniTable {
}
</style>
